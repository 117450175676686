@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .skeleton {
    @apply relative isolate w-max cursor-default select-none overflow-hidden bg-transparent decoration-clone text-transparent before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:border-t before:border-slate-100/20 before:bg-gradient-to-r before:from-transparent before:via-slate-100/20 before:to-transparent hover:cursor-default focus:outline-none;
  }

  .skeleton.on-overlay {
    @apply bg-black/50 before:border-slate-100/5 before:via-slate-100/5;
  }

  .skeleton-gray {
    @apply skeleton relative overflow-hidden bg-slate-300/50 before:border-slate-50/80 before:via-slate-50/80;
  }

  .skeleton * {
    cursor: default !important;
    color: transparent !important;
  }

  .debug {
    @apply border border-red-500;
  }
}

html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.no-scrollbar {
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.edge-mask {
  mask-image: linear-gradient(
    to right,
    transparent,
    white 180px calc(100% - 180px),
    transparent
  );
}

p {
  text-wrap: pretty;
}

h1,
h2,
h3,
h4 {
  text-wrap: balance;
}

leapa-customer {
  --leapa-color-brand: #2fad9c;
  --leapa-color-text: #334155;
}

.map-container {
  width: 100%;
  height: 100vh;
}

.embla {
  --slide-spacing: 1rem;
  --slide-size: 50%;
  --slide-height: 400px;
  padding: 1.6rem;
}
.embla__viewport {
  overflow: hidden;
  width: 100%;
  height: 400px;
  border: 1px solid red;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}
.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  object-fit: cover;
}
.embla__slide__number {
  width: 4.6rem;
  height: 4.6rem;
  z-index: 1;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  border-radius: 50%;
  background-color: rgba(var(--background-site-rgb-value), 0.85);
  line-height: 4.6rem;
  font-weight: 900;
  text-align: center;
  pointer-events: none;
}
.embla__slide__number > span {
  color: var(--brand-primary);
  background-image: linear-gradient(
    45deg,
    var(--brand-primary),
    var(--brand-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.6rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

[data-rmiz-modal-overlay='hidden'] {
  background-color: rgb(0 0 0 / 0.85) !important;
}
[data-rmiz-modal-overlay='visible'] {
  background-color: rgb(0 0 0 / 0.85) !important;
}
